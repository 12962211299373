import React from 'react';
import { normalizeType } from '../EnhancedMarkdown/components/EnhancedMarkdownStyled';
import { normalizeAbilityType } from '../EnhancedMarkdown/helpers/abilitySchools';
import Tooltip, { TooltipProps } from '../Tooltip/Tooltip';

export type BarProps = {
  type?: string;
  percent: number | string;
  height?: number;
  tooltipProps?: Omit<TooltipProps, 'kind' | 'children'>;
  className?: string;
  opacity?: number | null;
  darken?: boolean;
};

export default function Bar({
  type,
  percent,
  height = 16,
  tooltipProps,
  className,
  darken = false,
  opacity = null,
}: BarProps): JSX.Element {
  const width = Number(percent.toString()) <= 0 ? '1px' : `${percent}%`;
  const typeClassName = normalizeType(normalizeAbilityType(type)) + '-bg';

  let getChild = (width: string) => (
    <div
      className={`bar ${typeClassName} ${className ?? ''}`}
      style={{
        width,
        height: `${height}px`,
      }}
    />
  );

  if (opacity !== null) {
    getChild = (width: string) => (
      <div className={`bar__big-bar  ${className ?? ''}`}>
        <div
          className={typeClassName}
          style={{
            width,
            height: `${height}px`,
            opacity,
          }}
        />
        {opacity < 1 ? (
          <div
            className={`bar__small-bar ${typeClassName}`}
            style={{
              left: 0,
              width,
              opacity: 1 - opacity,
            }}
          />
        ) : null}
      </div>
    );
  }

  if (darken) {
    getChild = (width: string) => (
      <div
        className={`bar__container ${className ?? ''}`}
        style={{
          width,
          height: `${height}px`,
        }}
      >
        <div
          className={`bar ${typeClassName}`}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        <div className={'bar__darken'} />
      </div>
    );
  }

  if (tooltipProps) {
    return (
      <Tooltip
        {...tooltipProps}
        placementOptions={{
          alignment: 'start',
        }}
        triggerProps={{ style: { width } }}
      >
        {getChild('100%')}
      </Tooltip>
    );
  }

  return getChild(width);
}
