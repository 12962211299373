import React from 'react';
import LazyLoad from 'react-lazyload';
import Icon, { IconProps } from '../../Icon/Icon';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';

type EnhancedMarkdownEncounterIconProps = Pick<
  IconProps,
  'src' | 'rounded' | 'bordered' | 'children' | 'size' | 'className'
>;

export default function EnhancedMarkdownIcon({
  src,
  bordered,
  rounded,
  children,
  size,
  className,
}: EnhancedMarkdownEncounterIconProps): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  const value = (
    <span className='enhanced-markdown-icon'>
      <Icon
        src={src}
        rounded={rounded}
        bordered={bordered}
        size={size}
        className={className}
      >
        {children}
      </Icon>
    </span>
  );

  if (lazyLoad) {
    return <LazyLoad style={{ display: 'inline-block' }}>{value}</LazyLoad>;
  }

  return value;
}
