import React from 'react';
import LazyLoad from 'react-lazyload';
import GearIcon, { GearIconProps } from '../../GearIcon/GearIcon';
import useEnhancedMarkdownOptions from '@resources/js/contexts/useEnhancedMarkdownOptions';

type EnhancedMarkdownGearIconProps = {
  children?: string;
  type?:
    | 'Artifact'
    | 'Legendary'
    | 'Epic'
    | 'Rare'
    | 'Uncommon'
    | 'Common'
    | 'CommonWhite'
    | number;
} & Pick<
  GearIconProps,
  | 'id'
  | 'icon'
  | 'size'
  | 'extraInfo'
  | 'rounded'
  | 'bordered'
  | 'type'
  | 'side'
  | 'subLabel'
  | 'isAbility'
> & {
    stats?: string;
    enchants?: string;
    gems?: string;
    extraItems: string | null;
  };

export default function EnhancedMarkdownGearIcon({
  stats: statsString,
  enchants: enchantsString,
  gems: gemsString,
  children,
  id,
  icon,
  size,
  extraInfo,
  rounded,
  bordered,
  type,
  subLabel,
  side,
  isAbility,
  extraItems: extraItemsString,
}: EnhancedMarkdownGearIconProps): JSX.Element {
  const lazyLoad = useEnhancedMarkdownOptions().lazyLoad;

  let stats;
  let gems;
  let enchants;
  let extraItems;

  if (statsString) {
    try {
      stats = JSON.parse(statsString);
    } catch {
      // ignore
    }
  }

  if (gemsString) {
    try {
      gems = JSON.parse(gemsString);
    } catch {
      // ignore
    }
  }

  if (enchantsString) {
    try {
      enchants = JSON.parse(enchantsString);
    } catch {
      // ignore
    }
  }

  if (extraItemsString) {
    try {
      extraItems = JSON.parse(extraItemsString);
    } catch {
      // ignore
    }
  }

  const component = (
    <span className='enhanced-markdown-gear-icon'>
      <GearIcon
        id={id}
        icon={icon}
        size={size}
        extraInfo={extraInfo}
        rounded={rounded}
        bordered={bordered}
        stats={stats}
        enchants={enchants}
        gems={gems}
        type={type}
        side={side}
        isAbility={isAbility}
        subLabel={subLabel}
        extraItems={extraItems}
      >
        {children}
      </GearIcon>
    </span>
  );

  if (lazyLoad) {
    return <LazyLoad style={{ display: 'inline-block' }}>{component}</LazyLoad>;
  }

  return component;
}
