import * as Sentry from '@sentry/react';
import { SDK_VERSION } from '@sentry/core';
import { defaultRequestInstrumentationOptions } from '@sentry/tracing';

/**
 * due to requiring the `serverless` target in next.config.js and
 * `@serverless/cli` not supporting Next.js 12 where said target is deprecated,
 * we have to do some manual work which would otherwise would have been
 * orchestrated by `withSentryConfig`
 */

// https://github.com/getsentry/sentry-javascript/blob/master/packages/nextjs/src/config/webpack.ts#L144
// @ts-expect-error doesn't need to be strictly typed
global.__rewriteFramesDistDir = '.next';

const ignoredErrorMessageSlices = [
  'getViewportGeometry',
  'websredir',
  'NS_ERROR_NOT_INITIALIZED',
  'insertBefore',
  "properties of null (reading 'document')",
  "Failed to execute 'send' on 'XMLHttpRequest'",
  "Failed to execute 'observe' on 'IntersectionObserver'",
  'The node to be removed is not a child of this node',
  'ifameElement',
  'window.top.document',
  'trackAdhesionQuatiles',
  'ceCurrentVideo',
  'window.top',
];

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_CMS_SENTRY_DSN, // injected in next.config.js
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV, // also injected
  release: process.env.NEXT_PUBLIC_RELEASE_SHA, // same
  enabled: process.env.NODE_ENV !== 'development',
  // copied from https://github.com/getsentry/sentry-javascript/blob/master/packages/nextjs/src/index.client.ts
  _metadata: {
    sdk: {
      name: 'sentry.javascript.react',
      packages: [
        {
          name: `npm:@sentry/react`,
          version: SDK_VERSION,
        },
      ],
      version: SDK_VERSION,
    },
  },
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['archon'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  tracePropagationTargets: [
    ...defaultRequestInstrumentationOptions.tracingOrigins,
    /^(api\/)/,
  ],

  allowUrls: [
    /^https?:\/\/assets\.rpglogs\.com/i,
    /^https?:\/\/(([a-z.\-_]+)\.)?warcraftlogs\.com/,
    /^https?:\/\/(([a-z.\-_]+)\.)?fflogs\.com/i,
    /^https?:\/\/(([a-z.\-_]+)\.)?esologs\.com/i,
    /^https?:\/\/(([a-z.\-_]+)\.)?swtorlogs\.com/i,
  ],
  // denyUrls: [
  //   /js7k.com/i,
  //   /flashtalking.com/i,
  //   /doubleclick.net/i,
  //   /intergi.com/i,
  //   /flashtalking.com/i,
  //   /yieldmo.com/i,
  //   /media.net/i,
  //   /googleapis.com/i,
  //   /33across.com/i,
  //   /sonobi.com/i,
  //   /2mdn.net/i,
  //   /amazon-adsystem.com/i,
  //   /b2c.com/i,
  //   /yimg.com/i,
  //   /moatads.com/i,
  //   /3lift.com/i,
  //   /adcanvas.com/i,
  //   /ntv.io/i,
  //   /adnxs-simple.com/i,
  //   /strikeforce/i,
  //   /adlooxtracking.com/i,
  //   /ad-score.com/i,
  // ],
  beforeSend(event, hint) {
    const error = hint.originalException;

    if (error instanceof TypeError) {
      const message = error.message.toLowerCase();

      if (
        message.includes('failed to fetch') &&
        (error.stack?.includes('pubads') ||
          error.stack?.includes('speed-insights'))
      ) {
        return null;
      }
    }

    if (
      error instanceof Error &&
      ignoredErrorMessageSlices.some(
        (slice) =>
          error.message.includes(slice) ||
          (error.stack ? error.stack.includes(slice) : false)
      )
    ) {
      return null;
    }

    return event;
  },
});

Sentry.getCurrentScope().setTag('runtime', 'browser');
Sentry.addEventProcessor((event) =>
  event.type === 'transaction' && event.transaction === '/404' ? null : event
);
